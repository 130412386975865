import "../FounderSection.css";
import CEO from "../assets/img/CEO.png";
import CFO from "../assets/img/CFO.png";
import MD from "../assets/img/MD.png";

const FounderSection = () => {
  return (
    <div class="responsive-container-block bigContainer">
      <div class="responsive-container-block Container bottomContainer CEO">
        <div class="ultimateImg">
          {/* <img class="mainImg" src={CEO} /> */}
          {/* <div class="purpleBox">
            <p class="purpleText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
              purus lectus viverra in semper nec pretium mus.
            </p>
            <img
              class="stars"
              src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg"
            />
          </div> */}
        </div>
        <div class="allText bottomText">
          <p class="text-blk headingText">CEO</p>
          <p class="text-blk subHeadingText">Mr. Chandanpreet Singh</p>
          <p class="text-blk description">
            20 years Industry experience. Working at renowned Pharma experiences
            like Universal, Sanofi and Maxzimaa. Currently Looking after entire
            sales and marketing functions of Maxversal Medicare.
          </p>
          {/* <a class="explore">View Domains</a> */}
        </div>
      </div>
      <div class="responsive-container-block Container bottomContainer MD">
        <div class="ultimateImg">
          {/* <img class="mainImg" src={MD} /> */}
          {/* <div class="purpleBox">
            <p class="purpleText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
              purus lectus viverra in semper nec pretium mus.
            </p>
            <img
              class="stars"
              src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg"
            />
          </div> */}
        </div>
        <div class="allText bottomText">
          <p class="text-blk headingText">MD (MANAGING DIRECTOR)</p>
          <p class="text-blk subHeadingText">Mr. HARLAL KHILERI</p>
          <p class="text-blk description">
            15 years of Experience in Hospital Industry and construction.
          </p>
          {/* <a class="explore">View Domains</a> */}
        </div>
      </div>
      {/* <div class="responsive-container-block Container bottomContainer CFO">
        <div class="ultimateImg">
          <img class="mainImg" src={CFO} />
        </div>
        <div class="allText bottomText">
          <p class="text-blk headingText">CFO</p>
          <p class="text-blk subHeadingText">Mr. Sitaram Dudi</p>
          <p class="text-blk description">
            20 years in Mining Industry. Worked at Dudi constructions ( with
            more than 100Cr Turnover).
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default FounderSection;
