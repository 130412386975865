import React, { Component } from "react";
import { Link } from "react-router-dom";
import whatwedo from "../assets/img/whatwedo.jpg";
import whoweare from "../assets/img/whoweare.jpg";
import Contact from "../assets/img/LebenswerkContact.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import roas from "../assets/img/roas.png";
import yoe from "../assets/img/yoe.png";
import maretplace from "../assets/img/marketplace.png";

import FounderSection from "./FounderSection";
import ClientFeedback from "./ClientFeedback";
import CoreValues from "./CoreValues";
import freejoint from "../assets/portfolio/freejoint_plus.jpg";
import lovbon_ct from "../assets/portfolio/lovbon_ct.png";
import L_curra from "../assets/portfolio/L_curra.png";
import lovbon_D3 from "../assets/portfolio/lovbon_D3.png";
import LOVFOL from "../assets/portfolio/LOVFOL.png";
import tyorut from "../assets/portfolio/tyorut.png";
import pre_nt from "../assets/portfolio/pre_nt.png";
import toco_lc from "../assets/portfolio/toco_lc.png";
import Toconerv_z from "../assets/portfolio/Toconerv_z.png";
import Magease from "../assets/portfolio/Magease.png";
import Lovbon_dc_kid from "../assets/portfolio/Lovbon_dc_kid.png";
import EDF_Gold from "../assets/portfolio/EDF_Gold.png";
import BCRAB_DSR from "../assets/portfolio/BCRAB_DSR.png";
import MAX_PDSR from "../assets/portfolio/MAX_PDSR.png";
import Roxim from "../assets/portfolio/Roxim.png";
import Deflazacort from "../assets/portfolio/Deflazacort.png";
import l_curra_300 from "../assets/portfolio/l_curra_300.jpg";

class Home extends Component {
  render() {
    const accordionData = [
      {
        title: "Are E-Commerce Solutions Important?",
        content:
          "The e-commerce industry is ever-changing and e-commerce solutions are crucial for coping with frequent advancements. That’s why online sellers get in touch with an e-commerce business solutions provider who can keep up with constant technological and industrial e-commerce market trends. ",
      },
      {
        title: "How Will You Promote My Online Business?",
        content:
          "We have an experienced team of SEO Experts who have been handling digital promotions for e-commerce businesses for quite a few years. They boost your brand name through keyword integration and sponsored ads.",
      },
      {
        title: "What E-Commerce Services Do You Provide?",
        content:
          "Lebenswerk Consulting is a trusted e-commerce business management company that provides end-to-end solutions for \n •	E-Commerce Account Management \n •	Business Consultancy \n •	HR Consultancy \n •	Allied Services \n",
      },
      {
        title: "Which E-Commerce Platforms Do You Deal With?",
        content:
          "Some of the key e-commerce platforms we deal with are categorized above.",
      },
      {
        title: "I Want To Launch My Brand On Marketplaces, Will You Help Me?",
        content:
          "Of course. We have assisted many business owners in getting their brand listed online and expanding their verticals also.",
      },
    ];
    return (
      <>
        <header class="jumbotron video-container">
          {/* <video id="myVideo" class="" autoPlay muted loop >
                <source src={Headervideo} type="video/mp4" />
              
              </video>  */}

          {/* <div class="icon-bar">
            <a
              href="https://api.whatsapp.com/send?phone=919953559179"
              class=""
              role="button"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div> */}

          <div class="container" id="bgimg">
            <div class="row row-header">
              <div class="col-12 col-sm-6 intro" id="reservebtn">
                {/* <h5>Our Experience in the Areas</h5> */}
                <div className="headerContent">
                  <h1>
                    <span>B U I L D I N G</span>&nbsp;&nbsp;
                    <br class="hide-hero1" />
                    <span>C O N S U M E R</span>&nbsp;&nbsp;
                    <br class="hide-hero" />B U S I N E S S{" "}
                  </h1>
                </div>
                <br />
                <br />

                <div class="css-typing">
                  <p>We Absorb Complexity</p>
                  <p>We Deliver Clarity.</p>
                </div>
                <br />

                <br />
                <a
                  href=""
                  data-placement="bottom"
                  class="btn btn-lg "
                  role="button"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          {/* <h6 className="text-center Aboutcomp">
            Lebenswerk Consulting is the Best E-Commerce Account Management
            Service Provider In Delhi NCR
          </h6> */}
        </header>
        <div class="container-fluid industry">
          {/* <header class="jumbotron banner-container">
            <div class="container" id="bgimg"></div>
          </header> */}
          <div class="row row-content main-intro-block">
            <div class="col-12  text-center">
              <h1 className="main-heading">
                At Maxversal Medicare, we're passionate about providing
                affordable, high-quality medicines to our customers across India
              </h1>
              {/* 
              <h5>
                At Maxversal Medicare, we're passionate about providing
                affordable, high-quality medicines to our customers across India
              </h5> */}

              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <p class="mt-4">
                    Maxversal Medicare is the best Healthcare Management Service
                    Provider in India catering to the diverse needs of online
                    sellers. We serve diverse industries including Fashion,
                    Lifestyle, Footwear, Stationery, FMCG, Retail, Consumer, and
                    Internet. Our team of experts comprises highly qualified and
                    experienced people from IMS, XLRI, NIIT, FMS, and other
                    authorized institutions.
                  </p>
                </div>

                <span>
                  <Link to="/home">
                    <a
                      href="#reserve"
                      data-toggle="modal"
                      data-html="true"
                      data-target="#reserveModal"
                      data-placement="bottom"
                      class="btn btn-lg "
                      role="button"
                    >
                      Explore More
                    </a>
                  </Link>
                </span>
              </div>
            </div>
          </div>

          {/* <div class="row row-content fitplans">
            <div class="col-12 text-center p-2" id="discount-block">
              <h1 className="main-heading">
                Best E-Commerce Business Solutions Provider In India To Maximize
                Your Sales Revenue
              </h1>
              <h5>
                As more and more sellers are switching to online stores,
                generating sales has become troublesome. But if you have a
                professional e-commerce business solutions provider by your
                side, you won’t face any hassles.
              </h5>

              <div class="col-12">
                <div class="industry-grid">
                  <p class="mt-4">
                    Lebenswerk Consulting is an E-Commerce Account Management
                    Service Provider in India catering to the diverse needs of
                    online sellers. We serve diverse industries including
                    Fashion, Lifestyle, Footwear, Stationery, FMCG, Retail,
                    Consumer, and Internet. Our team of experts comprises highly
                    qualified and experienced people from IMS, XLRI, NIIT, FMS,
                    and other authorized institutions.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">What We Do </h3>

              <p class="mt-4">
                Business Transformation | e-Commerce Management | Sales
                Structure Planning | Corporate Planning | Annual Business
                Planning | Supply Chain Set-up | Processes Implementation |
                Training &amp; Development | Employees KRA &amp; KPI Formulation
                | GTM Planning | Financial Assessment | Performance Marketing
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                Channel Sales
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                LFR
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                E-commerce
              </a>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkRetail} class="img-fluid" />
                </div>
              </div>
            </div>
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-none d-sm-block">
                <div class="col p-5">
                  {" "}
                  <img src={LebenswerkFmcg} class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Who We Are </h3>

              <p class="mt-4">
                Lebenswerk's core team has vast experience into various consumer
                domains industries and worked across top-notch MNC &amp; Leading
                consumer companies in India. Academics background of our team
                are from XLRI - IMS - NIIT - FMS-ISS – Other Top Colleges,
                highly analytical and process oriented.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                XLRI
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                IMS
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                NIIT
              </a>
            </div>

            <div class="row  d-block d-sm-none">
              <div class="col p-5">
                <img src={LebenswerkFmcg} class="img-fluid" />
              </div>
            </div>
          </div> */}

          <div class="row row-content fitplans about-us-section">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>About Us</h2>
              <h5 className="motto">
                <span> &#8220;</span>
                Our Motto is to serve the nation with newer age medicines with
                cutting age nutraceuticals adorned with care to all the
                patients.
                <span> &#8221;</span>
              </h5>
              {/* <h4>
                <span>&#8220;</span>
                Our Motto is to serve the nation with newer age medicines with
                cutting age nutraceuticals adorned with care to all the
                patients.
                <span>&#8221;</span>
              </h4> */}
            </div>

            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Mission </h3>

              <p class="mt-4">
                To build the people, process and quality focussed healthcare
                company.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                Channel Sales
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                LFR
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                E-commerce
              </a>
            </div>

            <div class="col-sm-6 p-2">
              <div class="row">
                <div class="col p-5 mission">
                  <img src={whatwedo} class="img-fluid" />
                </div>
              </div>
            </div>
            <br />

            <div class="col-sm-6 p-2">
              <div class="row d-none d-sm-block">
                <div class="col p-3 vision">
                  {" "}
                  <img src={whoweare} class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 deals my-auto" id="reservebtn">
              <h3 class="">Vision </h3>

              <p class="mt-4">
                To be recognised as one of the topmost integrated healthcare
                provider to all patients.
              </p>

              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                XLRI
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                IMS
              </a>
              <a
                href="#reserve"
                data-toggle="modal"
                data-html="true"
                data-target="#reserveModal"
                data-placement="bottom"
                class="btn btn-lg "
                role="button"
              >
                NIIT
              </a>
            </div>

            <div class="row  d-block d-sm-none">
              <div class="col p-5">
                {" "}
                <img src={whoweare} class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="row row-content services services-offered-section">
            <div class="col-12  text-center" id="discount-block">
              <h2 class="mt-3">Areas of Interest</h2>
              <h5>Maxversal Medicare Core Services</h5>
              <div class="industry-grid mt-3">
                <div class="grid_row service-offered">
                  <div class="grid_item hospitals">
                    {/* <div class="grid_img">
                      <img src={EcomServices} class="img-fluid" />
                    </div> */}
                    {/* <span class="grid_text">Orthopaedics Care</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Orthopaedics Care</span>
                    </p>
                    <Link to="/ecommerce-account-management-services">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">E-Commerce Services </p>
                          <p>
                            As a professional e-commerce business solutions
                            provider in India Lebenswerk Consulting offer
                            full-stack e-commerce services across varied
                            industries. We assist businesses to market their
                            products/services and manage orders, inventory,
                            supply chain, payments, and listings. With
                            360-degree e-commerce solutions, we thoroughly
                            undertake analytical research for website
                            optimization and implement measures that positively
                            foster your brand image and generate more sales.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  <div class="grid_item pharma">
                    {/* <div class="grid_img">
                      <img src={HrServices} class="img-fluid" />
                    </div> */}
                    {/* <span class="grid_text">Gynaecology Care</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Gynaecology Care</span>
                    </p>
                    <Link to="/hr-consultancy-services">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">HR Consultancy Services </p>
                          <p>
                            We provide qualified people for your e-commerce
                            business management. Our strategic approach involves
                            an in-depth analysis of profile requirements and
                            accordingly placing the right people in the right
                            positions. We ascertain individual and collective
                            KPIs for employees and help you in decision-making
                            regarding how to invest in human resources. Our
                            employee performance analysis will save you both
                            time and money.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  <div class="grid_item retail_med">
                    {/* <div class="grid_img">
                      <img src={Consulting} class="img-fluid" />
                    </div> */}
                    {/* <span class="grid_text">Neurology care</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Neurology care</span>
                    </p>
                    <Link to="/ecommerce-business-management-company">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Business Consulting </p>
                          <p>
                            Managing business operations in the e-commerce world
                            is not that easy. That’s why organizations get
                            confused about deciding where to start and where to
                            end. Our experienced team knows the ins and outs in
                            this regard very well. We formulate several SOPs and
                            design the best methodologies as per your business
                            to analyze different areas related to online
                            marketing, employee maintenance, products/services
                            portfolio, financial allocation, supply chain, and
                            logistics smoothly.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  <div class="grid_item nutra">
                    {/* <div class="grid_img">
                      <img src={Consulting} class="img-fluid" />
                    </div>
                    <span class="grid_text">Nutraceuticals</span> */}
                    <p className="services-keywords">
                      <span class="grid_text">Nutraceuticals</span>
                    </p>
                    <Link to="/ecommerce-business-management-company">
                      {/* <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Business Consulting </p>
                          <p>
                            Managing business operations in the e-commerce world
                            is not that easy. That’s why organizations get
                            confused about deciding where to start and where to
                            end. Our experienced team knows the ins and outs in
                            this regard very well. We formulate several SOPs and
                            design the best methodologies as per your business
                            to analyze different areas related to online
                            marketing, employee maintenance, products/services
                            portfolio, financial allocation, supply chain, and
                            logistics smoothly.
                          </p>
                        </div>
                      </div> */}
                    </Link>
                  </div>
                  {/* <div class="grid_item">
                    <div class="grid_img">
                      <img src={AlliedServices} class="img-fluid" />
                    </div>
                    <span class="grid_text">Allied Services</span>
                    <p className="services-keywords">
                      Initiating New Business Avenues
                      <br />
                      Channel Partners Appointment
                      <br />
                      Marketing Services
                    </p>

                    <Link to="/mid-level-management-recruitment-consultants">
                      <div class="overlay">
                        <div class="text">
                          <p className="mb-3">Allied Services</p>
                          <p>
                            E-commerce store owners require such an e-commerce
                            business solutions provider who can diligently
                            decide on a channel partner for your collaboration.
                            We are a team of experienced expert professionals
                            who are highly skilled to figure out such a heavy
                            requirement. Our E-Commerce SEO team will also
                            foster your brand listings and highlight them in
                            front of your target audience. With seo-optimized
                            content and site, we assure you quality traffic and
                            an increase in revenue.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content domain-expert">
            <div class="col-12 text-center p-2 py-3" id="discount-block">
              <h2> Product Portfolios</h2>
              <h5>Discover the key Portfolios from Maxversal</h5>
            </div>
            <h5 className="portfolio_title text-center ">Orthopedic Profile</h5>
            <div class="col-12 py-2">
              <div class="domain-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={freejoint} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Freejoint Plus
                      <p>Indications : Tendonitis</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={lovbon_ct} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      LOVBON-CT
                      <p>Indications : Osteoporosis</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={tyorut} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Tyorut
                      <p>Indications : Bruises, Injury & Wound Management</p>
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={L_curra} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      L- Curra Q1
                      <p>Indications : Muscle sprain and cramps</p>
                    </div>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={lovbon_D3} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Lovbon-D3
                      <p>Indications : Vit D3 deficiency</p>
                    </div>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={LOVFOL} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      LOVFOL
                      <p>Indications : In Pregnancy and Lactation</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="portfolio_title text-center ">Neurology Profile</h5>
            <div class="col-12 py-2">
              <div class="domain-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={pre_nt} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      PRE-NT
                      <p>
                        Indications : Neuropathic pain, Fibromyalgia, DPNP RLS,
                      </p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={toco_lc} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Toco-LC
                      <p>Indications : PNP, B12 deficiency</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={Lovbon_dc_kid} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Lovbon-D3 Kid
                      <p>Indications : Vitamin D Deficiency, </p>
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={freejoint} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Freejoint Plus
                      <p>Indications : PNP, B12 deficiency</p>
                    </div>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={Toconerv_z} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Toconerv-Z Syp
                      <p>Indications : ADHD, Autism, Growth Development</p>
                    </div>
                  </div>
                  <div class="grid_item">
                    <div class="grid_img">
                      <img src={Magease} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Magease Tablets
                      <p>Indications : Migraine and Headache</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="portfolio_title text-center ">Gynecology Profile</h5>
            <div class="col-12 py-2">
              <div class="domain-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={LOVFOL} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      LOVFOL
                      <p>Indications : In Pregnancy and Lactation</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={l_curra_300} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      L-Curra 300
                      <p>Indications : In Fertility Man</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={lovbon_D3} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Lovbon-D3
                      <p>Indications : Vit D3 deficiency</p>
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={EDF_Gold} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      EDF-Gold
                      <p>Indications : Erectile dysfunction</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="portfolio_title text-center "> CP/GP Profile</h5>
            <div class="col-12 py-2">
              <div class="domain-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={BCRAB_DSR} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      BCRAB-DSR
                      <p>Indications : GERD, Gastritis</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={MAX_PDSR} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      MAX P-DSR
                      <p>Indications : GERD, Dyspepsia</p>
                    </div>
                  </div>
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={Roxim} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Roxim
                      <p>Indications : UTI, URTI, Bacterial Infections</p>
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <div class="grid_img">
                      <img src={Deflazacort} class="img-fluid" />
                    </div>
                    <div class="grid_text">
                      Deflazacort
                      <p>Indications : Asthma, infections and pain</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content clientNum stats">
            <div class="col-12 text-center p-2" id="discount-block">
              <h2>What Makes Us Stand Out</h2>
              <h5>Maxversal Medicare Transforms Your Business</h5>
            </div>
            <div class="projectnumber wow fadeInUp" data-wow-duration=".5s">
              <div class="main-total-box anim-counter">
                <div class="client-number">
                  <img src={roas} class="img-fluid grid_img" />
                  <strong class="newde">Innovative launch targeting </strong>
                  {/* <span data-content="home.stat.1">
                    Innovative launch targeting
                  </span> */}
                </div>
                <div class="client-number">
                  <img src={maretplace} class="img-fluid grid_img" />
                  <strong class="newde">Touched 10 Million Lives</strong>
                  {/* <span data-content="home.stat.1">
                    SKUs Listed on various Marketplaces
                  </span> */}
                </div>
                {/* <div class="client-number">
                  <img src={adspends} class="img-fluid grid_img" />
                  <strong>30 Mn</strong>
                  <span data-content="home.stat.2">
                    Ads Spends managed Efficiently
                  </span>
                </div> */}
                <div class="client-number">
                  <img src={yoe} class="img-fluid grid_img" />
                  <strong>(2+) Years of Service</strong>
                  {/* <span data-content="home.stat.3">
                    Years of Service Excellence
                  </span> */}
                </div>
              </div>
            </div>
          </div>

          <FounderSection />

          <CoreValues />

          <div class="row row-content contact">
            <div
              class="col-12 text-center px-5 align-items-center"
              id="whatsapp-block"
            >
              <h2>Feel Free to Contact Us 24x7 → &nbsp;</h2>
              {/*      
    <a href="mailto:info@lebenswerk.in"> <i class="fas fa-envelope"></i>
                      */}
              <a
                href="https://api.whatsapp.com/send?phone=918376865000"
                class="btn btn-lg whatspp-head"
                role="button"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="whatspp-head" />
              </a>
              <a href="tel:08376865000" class="btn btn-lg " role="button">
                <FontAwesomeIcon icon={faPhone} />
              </a>
              &nbsp;
              <img src={Contact} class="img-fluid" />
            </div>
          </div>
          <ClientFeedback />
        </div>
      </>
    );
  }
}

export default Home;
